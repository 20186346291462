import * as React from "react"
import { Link } from "gatsby"
import CTAPopup from "./CTABlock/CTAPopup"

export function CallToAction({ primaryButton, secondaryButton, hero }) {
  const [isShown, setIsShown] = React.useState(false)
  return (
    <>
      {isShown && (
        <CTAPopup
          onClose={e => {
            setIsShown(false)
          }}
        />
      )}
      <div className=" sm:flex sm:justify-center lg:justify-start">
        {primaryButton?.text && primaryButton?.link && (
          <div className="rounded-md w-full flex justify-center">
            {!hero.kontaktToPopup ? (
              <Link
                to={primaryButton.link}
                className="w-fit min-w-52 flex flex-nowrap items-center btn justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white primaryBg md:py-4 md:text-lg md:px-10"
              >
                {primaryButton.text}
              </Link>
            ) : (
              <button
                onClick={() => setIsShown(true)}
                className="w-fit min-w-52 flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white primaryBg md:py-3 md:text-lg md:px-10"
              >
                <div className="whitespace-nowrap m-0">
                  {primaryButton.text}
                </div>
              </button>
            )}
          </div>
        )}
        {secondaryButton?.text && secondaryButton?.link && (
          <div className="w-full mt-3 rounded-md sm:mt-0 sm:ml-3 flex justify-center">
            <a
              href={secondaryButton?.link.replaceAll(" ", "")}
              className="w-fit min-w-52 flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white md:text-lg secondaryBg md:px-10"
            >
              <div className="whitespace-nowrap m-0">
                {secondaryButton?.text}
              </div>
            </a>
          </div>
        )}
      </div>
    </>
  )
}
