import * as React from "react"
import { PortableText } from "@portabletext/react"
import Slider from "../Slider"
import { getImageUrl } from "../../utils/getImageUrl"
import LazyLoad from "react-lazy-load"
import parse from "html-react-parser"

const Content = ({ content, contentData }) => {
  return (
    <div className="elva content px-4 lg:p-2.5 pb-12 lg:pb-16">
      {contentData && parse(contentData)}
      {content &&
        content.map(item => {
          return (
            <div key={item._key}>
              <h3 className="mb-2 mt-5">
                <strong>{item.heading}</strong>
              </h3>
              <div>
                {item.image && (
                  <LazyLoad>
                    <img
                      className={`w-full h-full ${
                        item.layout === "imageleft"
                          ? "md:float-left md:mx-4 md:mb-4 md:w-1/2"
                          : `${
                              item.layout === "imageright"
                                ? "md:float-right md:mx-4 md:mb-4 md:w-1/2"
                                : ""
                            } `
                      }`}
                      style={{ maxWidth: `${item.imageSize}px` }}
                      src={getImageUrl(item.image?.asset?._ref)}
                      alt={item.heading}
                    />
                  </LazyLoad>
                )}
                <PortableText value={item.content} />

                {item._type === "logos" && (
                  <div className="flex flex-wrap justify-center gap-4 items-center">
                    {item.logos.map(image => (
                      <LazyLoad>
                        <img
                          className={`w-full h-full object-cover`}
                          style={{ maxWidth: `150px`, maxHeight: `150px` }}
                          src={getImageUrl(image?.asset?._ref)}
                        />
                      </LazyLoad>
                    ))}
                  </div>
                )}

                {item._type === "imageGrid" && (
                  <Slider data={item.gridImages} />
                )}
              </div>
              <div className="clear" />
            </div>
          )
        })}
    </div>
  )
}

export default Content
