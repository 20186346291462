import React, { useState } from "react"
import { PageContext } from "../../context/pageProvider"
import { getImageUrl } from "../../utils/getImageUrl"

import CTAPopup from "./CTAPopup"

const CTABlock = () => {
  const { meta } = React.useContext(PageContext)
  const { ctaBlock: sanityCtaBlock } = meta
  const [isShown, setIsShown] = useState(false)
  const showPopup = () => {
    setIsShown(true)
  }
  if (!sanityCtaBlock) {
    return null
  }
  return (
    <>
      {isShown && (
        <CTAPopup
          onClose={e => {
            setIsShown(false)
          }}
        />
      )}
      <div
        style={{
          backgroundImage: `url(
          ${getImageUrl(sanityCtaBlock.url?.asset?._ref)}
        )`,
        }}
        className="ctaBlock"
      >
        <div className="elva ctaBlock_content">
          <h2 className="text-4xl ctaBlock_text text_shadow">
            {sanityCtaBlock.title}
          </h2>
          <p className="mb-6 mt-4 text_shadow">{sanityCtaBlock.text} </p>
          <button
            onClick={showPopup}
            className="ctaBlock_btn rounded table font-semibold px-9 py-4 text-center mx-auto my-2.5 leading-none"
          >
            {sanityCtaBlock.buttonName}
          </button>
        </div>
      </div>
    </>
  )
}

export default CTABlock
